import React, {FunctionComponent, useCallback, useContext, useState} from 'react';
import {Button} from '../ui-components/Button/Button';
import './BookingService.scss';
import {BookingService} from "../../models/service";
import {SubmitContext} from "../../contexts/SubmitContext";
import {CONSTANTS} from "../../common/constants";
import {CompanyContext} from "../../contexts/CompanyContext";

interface BookingServiceTileProps {
    bookingService: BookingService;
    shouldShowOutletImage?: boolean;
    shouldShowDescriptionOutlet?: boolean;
    amountOfCharactersToShowOnDescription?: number;
    onSubmit: () => void;
}

export const BookingServiceTile: FunctionComponent<BookingServiceTileProps> = ({
                                                                                   bookingService,
                                                                                   shouldShowOutletImage,
                                                                                   shouldShowDescriptionOutlet,
                                                                                   amountOfCharactersToShowOnDescription = 300,
                                                                                   onSubmit
                                                                               }) => {
    const [showingFullDescription, setShowingFullDescription] = useState<boolean>(false);
    const company = useContext(CompanyContext).company;
    const submitContext = useContext(SubmitContext);

    const renderBookingServiceDescription = () => {
        if (bookingService.description && bookingService.description.length > 0) {
            if (shouldShowShowFullDescriptionButton()) {
                return `${bookingService.description.substr(0, amountOfCharactersToShowOnDescription).trim()}...`;
            } else {
                return bookingService.description;
            }
        } else {
            if (shouldShowDescriptionOutlet) {
                return '-';
            } else {
                return null;
            }
        }
    };

    const shouldShowShowFullDescriptionButton = () => {
        return bookingService.description.length >= amountOfCharactersToShowOnDescription && !showingFullDescription;
    };

    const onChooseBookingService = useCallback(() => {
        submitContext.setSelectedBookingService(bookingService);
        onSubmit();
    }, [submitContext]);

    const isPriceAvailable = useCallback(() => {
        return bookingService.booking_method !== CONSTANTS.BOOKING_SERVICE_METHODS.NO_PRICING_INFORMATION;
    }, [bookingService]);

    return (
        <section className={`booking-service-tile`}>
            <h3>{bookingService.title}</h3>
            {bookingService.logo_url || shouldShowOutletImage ?
                <section className="booking-service-logo">
                    {
                        bookingService.logo_url
                            ? <img src={bookingService.logo_url}/>
                            : <i className="fad fa-file-image logo-outlet"/>
                    }
                </section> : null}

            <section className="booking-service-items">
                <p className="booking-service-description">
                    {
                        renderBookingServiceDescription()
                    }

                    {
                        shouldShowShowFullDescriptionButton()
                            ? <span className='show-more-link' onClick={() => setShowingFullDescription(true)}>[Show More]</span>
                            : null
                    }
                </p>
            </section>
            <section className="booking-service-actions">
                <Button className='large-button'
                        onClick={onChooseBookingService}>{isPriceAvailable() ? company?.default_currency_object.symbol_native + (parseFloat(bookingService.total_price) || 0).toFixed(2) : 'Next'}</Button>
            </section>
        </section>
    );
};
