import React, {FunctionComponent} from 'react';
import styled from "styled-components";
import {CONSTANTS} from "../../../common/constants";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {

}

const StyledButton = styled.button`
  &&& {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: ${CONSTANTS.UI_PARAMS.INPUT_HEIGHT}px;
    min-width: ${CONSTANTS.UI_PARAMS.INPUT_MIN_WIDTH}px;
    border-radius: ${CONSTANTS.UI_PARAMS.INPUT_BORDER_RADIUS}px;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background: ${CONSTANTS.UI_PARAMS.FP_BRANDING_COLOR};
    color: white;
    transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
    
    @media ${CONSTANTS.BROWSER_BREAKPOINTS.MOBILE_AND_TABLET} {
      height: ${CONSTANTS.UI_PARAMS.INPUT_MOBILE_HEIGHT}px;
      padding: 10px;
    }
    
    &.secondary-button {
      background-color: #e8e8e8;
      color: ${CONSTANTS.UI_PARAMS.TEXT_COLOR};
    }
    
    &.full-width-button {
      width: 100%;
    }

    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }

    .button-content {
      display: flex;
      align-items: center;
      font-size: 0.9rem;

      @media ${CONSTANTS.BROWSER_BREAKPOINTS.MOBILE_AND_TABLET} {
        font-size: 1rem;
      }
      
      i {
        margin-top: 3px;
        
        @media ${CONSTANTS.BROWSER_BREAKPOINTS.MOBILE_AND_TABLET} {
          font-size: 1.5rem;
        }
      }

      i.right-button-icon {
        margin-left: 10px;

        @media ${CONSTANTS.BROWSER_BREAKPOINTS.MOBILE_AND_TABLET} {
          margin-left: 10px;
        }
      }

      i.left-button-icon {
        margin-right: 10px;

        @media ${CONSTANTS.BROWSER_BREAKPOINTS.MOBILE_AND_TABLET} {
          margin-right: 10px;
        }
      }
    }

    &.large-button {
      height: ${CONSTANTS.UI_PARAMS.INPUT_MOBILE_HEIGHT}px;
      padding: 10px;
      
      .button-content {
        font-size: 1.1rem;
        
        i {
          font-size: 1.5rem;
        }

        i.right-button-icon {
          margin-left: 10px;
        }
        
        i.left-button-icon {
          margin-right: 10px;
        }
      }
    }
  }
`;

export const Button: FunctionComponent<ButtonProps> = (props) => {
    const {children} = props;

    return (
        <StyledButton  {...props} className={`${props.className} button`}>
            <div className='button-content'>{children}</div>
        </StyledButton>
    );
}
