import React, {useContext, useEffect, useState} from "react";
import {CustomField} from "../models/custom-field";
import {CompanyContext} from "./CompanyContext";
import {getCustomFields} from "../api/custom-fields.api";

interface CustomFieldContextState {
    customFields: CustomField[];
}

export const CustomFieldContext = React.createContext({} as CustomFieldContextState);

export const CustomFieldProvider: React.FunctionComponent = ({children}) => {
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [isInited, setInited] = useState<boolean>(false);
    const company = useContext(CompanyContext).company;

    const loadCustomFields = async () => {
        const response = await getCustomFields(company?.id as number)
            .catch(()=>{

            });
        if(response) {
            setCustomFields(response as CustomField[]);
        }
    }

    useEffect(() => {
        if (company && company.id && !isInited) {
            setInited(true);
            loadCustomFields()
        }
    }, [company, isInited]);

    return (
        <CustomFieldContext.Provider
            value={{customFields}}>
            {children}
        </CustomFieldContext.Provider>
    )
}
