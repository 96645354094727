import React from 'react';
import './App.scss';
import {MainPage} from "./pages/MainPage/MainPage";
import {CompanyProvider} from "./contexts/CompanyContext";
import {SlugProvider} from "./contexts/SlugContext";
import {PreloaderProvider} from "./contexts/PreloaderContext";
import {FullScreenPreloader} from "./components/FullScreenPreloader/FullScreenPreloader";
import {BrowserRouter} from "react-router-dom";
import {SubmitProvider} from "./contexts/SubmitContext";
import {GoogleProvider} from "./contexts/GoogleContext";
import {PoweredByFieldpulse} from "./components/PoweredByFieldpulse/PoweredByFieldpulse";
import {ServiceProvider} from "./contexts/ServiceContext";
import {CustomFieldProvider} from "./contexts/CustomFieldContext";

function App() {
    return (
        <BrowserRouter>
            <SlugProvider>
                <PreloaderProvider>
                    <CompanyProvider>
                        <SubmitProvider>
                            <GoogleProvider>
                                <ServiceProvider>
                                    <CustomFieldProvider>
                                        <MainPage/>
                                    </CustomFieldProvider>
                                </ServiceProvider>
                            </GoogleProvider>
                        </SubmitProvider>
                        <FullScreenPreloader/>
                    </CompanyProvider>
                </PreloaderProvider>
            </SlugProvider>
        </BrowserRouter>
    );
}

export default App;
