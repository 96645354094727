import React, {useCallback, useContext, useEffect, useState} from "react";
import {Company} from "../models/company";
import {SlugContext} from "./SlugContext";
import {getCompanyInformation} from "../api/company.api";
import {PreloaderContext} from "./PreloaderContext";
import {ToastService} from "../service/ToastService";
import {CONSTANTS} from "../common/constants";
import moment from "moment-timezone";

interface CompanyContextState {
    company: Company | null;
    setCompany: (company: Company) => void;
    isAuNZCompany: () => boolean;
}

export const CompanyContext = React.createContext({} as CompanyContextState);

export const CompanyProvider: React.FunctionComponent = ({children}) => {
    const [company, setCompany] = useState<Company | null>(null);
    const slugName = useContext(SlugContext).slugName;
    const preloaderContext = useContext(PreloaderContext);

    const loadCompanyInformation = async () => {
        preloaderContext.showFullScreenPreloader();
        const company = await getCompanyInformation(slugName)
            .catch((error: Error) => {
                if (error && error.message) {
                    ToastService.middleScreenErrorToast(error.message);
                } else {
                    ToastService.middleScreenErrorToast(CONSTANTS.UNKNOWN_ERROR);
                }
            });
        if (company) {
            setCompany(company);
            initCompany(company);
        }
        preloaderContext.hideFullScreenPreloader();
    }

    const initCompany = (company: Company) => {
        if(company.is_booking_portal_manual_timezone) {
            moment.tz.setDefault(company.booking_portal_manual_timezone);
        }
    }

    const isAuNZCompany = useCallback(() => {
        return company?.country === 'AU' || company?.country === 'NZ';
    }, [company]);

    useEffect(() => {
        if (slugName) {
            loadCompanyInformation();
        }
    }, [slugName]);

    return (
        <CompanyContext.Provider value={{company, setCompany, isAuNZCompany}}>
            {children}
        </CompanyContext.Provider>
    )
}
