import React, {ForwardRefRenderFunction} from 'react';
import styled from "styled-components";
import {CONSTANTS} from "../../../common/constants";

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
}

const StyledTextarea = styled.textarea`
  &&& {
    min-height: ${CONSTANTS.UI_PARAMS.INPUT_HEIGHT}px;
    min-width: ${CONSTANTS.UI_PARAMS.INPUT_MIN_WIDTH}px;
    border-radius: ${CONSTANTS.UI_PARAMS.INPUT_BORDER_RADIUS}px;
    border: 1px solid ${CONSTANTS.UI_PARAMS.DIVIDER_COLOR};
    resize: none;
    height: unset;
  }
`;

export const Textarea = React.forwardRef<ForwardRefRenderFunction<HTMLTextAreaElement>, TextareaProps>((props, ref: any) => {
    return (
        <StyledTextarea {...props} className={'textarea'}/>
    )
});
