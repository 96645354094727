import React, {FunctionComponent} from 'react';
import './ContactFileTile.scss';

interface ContactFileTileProps {
    file: File;
    onDeleteFile: (file: File) => void;
}

export const ContactFileTile: FunctionComponent<ContactFileTileProps> = (props) => {

    const deleteFile = () => {
        props.onDeleteFile(props.file);
    }

    return (
        <article className='contact-file-tile'>
            <span>{props.file.name}</span>
            <i className="far fa-times delete-contact-file-icon" onClick={deleteFile}/>
        </article>
    );
};
