import React, {FunctionComponent, useCallback, useContext, useState} from 'react';
import './ServicePurchaseStep.scss';
import {SubmitContext} from "../../contexts/SubmitContext";
import {PaymentModal} from "invoice-public-app/publish/components/PaymentModal/PaymentModal";
import {Button} from "../../components/ui-components/Button/Button";
import {PreloaderProvider} from "invoice-public-app/publish/context/PreloaderContext";
import {ServerDataContext} from "invoice-public-app/publish/context/ServerDataContext";
import {Customer} from "invoice-public-app/publish/models/customer.model";
import {FileSection} from "invoice-public-app/publish/models/file.model";
import {Job} from "invoice-public-app/publish/models/job.model";
import {ItemSection} from "invoice-public-app/publish/models/item.model";
import {Company} from "invoice-public-app/publish/models/company.model";
import {AchPayment} from "invoice-public-app/publish/models/ach.payment.model";
import {CreditCardPayment} from "invoice-public-app/publish/models/payment";
import {SlugContext} from "../../contexts/SlugContext";
import {useNavigate} from "react-router-dom";
import {CompanyContext} from "../../contexts/CompanyContext";
import {SETTINGS} from "../../common/settings";
import {CONSTANTS} from "../../common/constants";
import {MiddlePagePreloader} from "invoice-public-app/publish/components/MiddlePagePreloader/MiddlePagePreloader";


interface ServicePurchaseStepProps {
    onSkipPayment: () => void;
    onPayment: () => void;
}

export const ServicePurchaseStep: FunctionComponent<ServicePurchaseStepProps> = (props) => {
    const service = useContext(SubmitContext).selectedBookingService;
    const submitContext = useContext(SubmitContext);
    const slugName = useContext(SlugContext).slugName;
    const navigate = useNavigate();
    const company = useContext(CompanyContext).company;
    const [isShowPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const serverDataContextContext = useContext(ServerDataContext);

    const onClosePaymentModal = useCallback(() => {
        setShowPaymentModal(false);
    }, [setShowPaymentModal]);

    const showPaymentButton = useCallback(() => {
        const invoice: any = {};
        if (submitContext.selectedBookingService?.booking_method === CONSTANTS.BOOKING_SERVICE_METHODS.FULL_PAYMENT) {
            invoice.payment_request_amount = String(submitContext.selectedBookingService?.total_price);
        } else {
            invoice.payment_request_amount = String(submitContext.selectedBookingService?.payment_deposit);
        }
        (window as any).PAGE_DATA = {};

        const invoiceCompany: Company = {
            is_payrix_enabled: company?.cardpointe_merchant_id !== null && company?.payment_integration === 'payrix',
            is_payrix_us_enabled: company?.cardpointe_merchant_id !== null && company?.payment_integration === 'payrixUS',
            currency_sign: company?.default_currency_object?.symbol_native,
            payrix_token_api_url: `${SETTINGS.getPayrixApiUrl()}/businesses/${company?.payment_business_key}/services/tokenize-card`,
            payrix_us_token_api_url: `${SETTINGS.getPayrixUSApiUrl()}/tokens`,
            payrix_us_public_api_token: SETTINGS.getPayrixUSPublicApiToken(),
            payrix_us_merchant_id: company?.cardpointe_merchant_id,
            currency_code: company?.default_currency_object?.code
        } as any;

        serverDataContextContext.setMetaData({
            company: invoiceCompany as Company,
            invoice: invoice as any,
            customer: {} as Customer,
            file: {is_files_visible: false, files: []} as FileSection,
            job: {} as Job,
            item: {} as ItemSection
        });
        setShowPaymentModal(true);
    }, [setShowPaymentModal]);

    const onSubmitAchPaymentInformation = useCallback((payment: AchPayment) => {
        payment.type = 'ach';
        payment.amount = formatAmount(payment.amount);
        submitContext.setPaymentInformation(payment);
        submitContext.submit();
    }, [submitContext])

    const formatAmount = (amount: string) => {
        if (amount.indexOf('.') === -1) {
            amount = `${amount}.00`;
        } else if (amount.indexOf('.') === amount.length - 2) {
            amount = `${amount}0`;
        } else {
            amount = amount.slice(0, amount.indexOf('.') + 3);
        }
        return amount
    }

    const onSubmitCreditCardPaymentInformation = useCallback((payment: CreditCardPayment) => {
        submitContext.setPaymentInformation({
            amount: formatAmount(payment.amount),
            account: payment.creditCardNumber,
            expiry: `${payment.monthExpiry}${payment.yearExpiry}`,
            notes: payment.paymentNotes,
            postal: payment.zipCode,
            cvv2: payment.cvv,
            holder_name: payment.cardholderName
        });
        if (company?.cardpointe_merchant_id !== null && company?.payment_integration === 'payrix') {
            submitContext.setPaymentInformation({
                amount: formatAmount(payment.amount),
                account: payment.creditCardNumber,
                notes: payment.paymentNotes,
            });
        }
        submitContext.submit();
    }, [submitContext, company]);

    return <section className='step-container service-purchase-step'>
        <h1>
            {service?.title}
        </h1>

        <div className='service-info'>
            {service?.logo_url && <div className='logo-container'>
                <img alt='service-logo' src={service.logo_url}/>
            </div>}
            {service?.booking_method === CONSTANTS.BOOKING_SERVICE_METHODS.DEPOSIT_PAYMENT && <p><span
                className="bold-text">Deposit:</span> {company?.default_currency_object.symbol_native}{service?.payment_deposit}
            </p>}
            <p><span
                className="bold-text">Total:</span> {company?.default_currency_object.symbol_native}{service?.total_price}
            </p>
        </div>

        <div>
            <Button className='large-button' onClick={showPaymentButton}>
                <i className="fad fa-credit-card left-button-icon"/>
                Pay Now
            </Button>
        </div>
        <PreloaderProvider>
            <MiddlePagePreloader/>
            {isShowPaymentModal && <PaymentModal onlySubmit={true} onCloseModal={onClosePaymentModal}
                                                 onSubmitAchPaymentInformation={onSubmitAchPaymentInformation}
                                                 onSubmitCreditCardPaymentInformation={onSubmitCreditCardPaymentInformation}/>}
        </PreloaderProvider>
        <p className='link-text' onClick={props.onSkipPayment}>Continue without payment</p>
    </section>
};
