import React, {FunctionComponent, useCallback} from 'react';
import "./MonthDay.scss"
import {DayWithSlots} from "../../WeekCalendar/WeekCalendar";


interface MonthDayProps {
    dayWithSlot: DayWithSlots;
    isActive: boolean;
    onSetActiveDay: (dayWithSlot: DayWithSlots) => void;
}

export const MonthDay: FunctionComponent<MonthDayProps> = (props) => {
    const getClassNames = useCallback(() => {
        const classes = ['month-day'];
        if (props.dayWithSlot.slots.length) {
            classes.push('month-day-with-time');
        }

        if (props.isActive) {
            classes.push('active-month-day');
        }
        return classes.join(' ');
    }, [props.dayWithSlot, props.isActive]);

    return (
        <div className={getClassNames()} onClick={() => {
            if (props.dayWithSlot.slots.length) {
                props.onSetActiveDay(props.dayWithSlot);
            }
        }}>
            <div className='day-content'>{props.dayWithSlot.day.format('DD')}</div>
        </div>
    );
};
