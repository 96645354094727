import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import "./WeekDaySlots.scss"
import {DaySlot} from "../../../models/day-slot";
import {WeekDaySlot} from "./WeekDaySlot/WeekDaySlot";

interface WeekDaySlotsProps {
    slots: DaySlot[];
    activeWeekDaySlot: DaySlot | null;
    onSetActiveSlot: (slot: DaySlot) => void;
}

export const WeekDaySlots: FunctionComponent<WeekDaySlotsProps> = (props) => {
    const [morningTimes, setMorningTimes] = useState<DaySlot[]>([]);
    const [afternoonTimes, setAfternoonTimes] = useState<DaySlot[]>([]);

    useEffect(() => {
        const morningTimes: DaySlot[] = [];
        const afternoonTimes: DaySlot[] = [];
        for (let slot of props.slots) {
            if (slot.startTime.format('A').indexOf('AM') === -1) {
                afternoonTimes.push(slot);
            } else {
                morningTimes.push(slot);
            }
        }
        setMorningTimes(morningTimes);
        setAfternoonTimes(afternoonTimes);
    }, [props.slots]);

    const renderSlotList = (slotList: DaySlot[]) => {
        return <>
            {slotList.map((slot: DaySlot) => {
                return <WeekDaySlot key={slot.start_timestamp} slot={slot} isActive={slot === props.activeWeekDaySlot}
                                    onSetSlot={() => {
                                        props.onSetActiveSlot(slot);
                                    }}/>
            })}
        </>
    }

    return (
        <section className='week-day-slots-container'>
            {
                morningTimes.length > 0
                && <section className='week-day-slots-section'>
                    <p className='week-day-slots-section-title'>Morning</p>

                    <div className="slot-list">
                        {
                            renderSlotList(morningTimes)
                        }
                    </div>
                </section>
            }
            {
                afternoonTimes.length > 0
                && <section className='week-day-slots-section'>
                    <p className='week-day-slots-section-title'>Afternoon</p>
                    <div className="slot-list">
                        {
                            renderSlotList(afternoonTimes)
                        }
                    </div>
                </section>
            }
        </section>
    );
};
