export class SETTINGS {
    public static getApiUrl() {
        if(SETTINGS.isProduction()) {
            return 'https://apibe.fieldpulse.com/v2.5'
        }
        return process.env?.API_URL || 'https://developer.fieldpulse.com/v2.5';
    }

    public static isProduction() {
        return process.env.REACT_APP_ENV === 'PROD' || false;
    }

    public static getPayrixUSApiUrl() {
        if (SETTINGS.isProduction()) {
            return 'https://payrix-api.fieldpulse.com';
        }
        return 'https://test-api.payrix.com';
    }

    public static getPayrixUSPublicApiToken() {
        if (SETTINGS.isProduction()) {
            return 'c562be9fe3d0efdc3c177312e2475886';
        }
        return 'd3c74f0cbd8254e0d8585642ebe24e91';
    }

    public static getPayrixApiUrl() {
        if (SETTINGS.isProduction()) {
            return 'https://rest.paymentsapi.io';
        }
        return 'https://sandbox.rest.paymentsapi.io';
    }
}
