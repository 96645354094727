import React, {FunctionComponent, useCallback, useContext, useEffect, useState} from 'react';
import {Aside} from "../../components/Aside/Aside";
import {CompanyContext} from "../../contexts/CompanyContext";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ContactStep} from "../../steps/ContactStep/ContactStep";
import {Customer} from "../../models/customer";
import {SlugContext} from "../../contexts/SlugContext";
import {NotSelectedStep} from "../../steps/NotSelectedStep/NotSelectedStep";
import {CONSTANTS} from "../../common/constants";
import './MainPage.scss';
import {BookingStep} from "../../steps/BookingStep/BookingStep";
import {SubmitContext} from "../../contexts/SubmitContext";
import {SuccessStep} from "../../steps/SuccessStep/SuccessStep";
import {ServiceStep} from "../../steps/ServiceStep/ServiceStep";
import {ServiceCategoryStep} from "../../steps/ServiceCategoryStep/ServiceCategoryStep";
import {ServicePurchaseStep} from "../../steps/ServicePurchaseStep/ServicePurchaseStep";
import {InvoiceProvider} from "invoice-public-app/publish/context/InvoiceContext";
import {CompanyProvider} from "invoice-public-app/publish/context/CompanyContext";
import {ServerDataProvider} from "invoice-public-app/publish/context/ServerDataContext";
import {ThemeProvider} from "invoice-public-app/publish/context/ThemeContext";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {CustomField} from "../../models/custom-field";
import {useIsMobileDevice} from "../../hooks/useIsMobileDevice";


export const MainPage: FunctionComponent = (props) => {
    const company = useContext(CompanyContext);
    const location = useLocation();
    const slugName = useContext(SlugContext).slugName;
    const navigate = useNavigate();
    const submitContext = useContext(SubmitContext);
    const [contactStepTitle, setContactStepTitle] = useState<string | null>(null);
    const [step, setStep] = useState<number>();
    const isMobileDevice = useIsMobileDevice(true);

    useEffect(() => {
        if (company.company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.FORM && window.location.pathname === `/${slugName}`) {
            navigate(`/${slugName}/contact`);
        }
        if (company.company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE_ONLY && window.location.pathname === `/${slugName}`) {
            navigate(`/${slugName}/booking`);
        }
    }, [company]);

    const isShowingHomeButton = () => {
        if (isMobileDevice && !isScheduleAvailable() && !isServiceAvailable()) {
            return false;
        }

        return location.pathname !== `/${slugName}`;
    };

    const isScheduleAvailable = useCallback(() => {
        return company.company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE || company.company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE_AND_SERVICE;
    }, [company]);

    const isServiceAvailable = useCallback(() => {
        return company.company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SERVICE || company.company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE_AND_SERVICE;
    }, [company]);

    const onSelectStep = useCallback((step: number) => {
        setStep(step);
        if (step === CONSTANTS.STEPS.CONTACT) {
            navigate(`/${slugName}/contact`);
        } else if (step === CONSTANTS.STEPS.BOOKING) {
            navigate(`/${slugName}/booking`);
        } else if (step === CONSTANTS.STEPS.SERVICE) {
            navigate(`/${slugName}/service/category`);
        } else if (step === CONSTANTS.STEPS.BOOKING_AND_SERVICE) {
            navigate(`/${slugName}/service/category`);
        } else if (step === CONSTANTS.STEPS.PURCHASE) {
            navigate(`/${slugName}/service/purchase`);
        }
    }, [slugName, navigate]);

    const onSubmitContactStep = useCallback((customer: Customer, customFields: CustomField[], files: File[]) => {
        submitContext.setCustomer(customer);
        submitContext.setCustomFields(customFields);
        submitContext.setFiles(files);
        if (submitContext.selectedBookingService?.booking_method === CONSTANTS.BOOKING_SERVICE_METHODS.FULL_PAYMENT || submitContext.selectedBookingService?.booking_method === CONSTANTS.BOOKING_SERVICE_METHODS.DEPOSIT_PAYMENT) {
            onSelectStep(CONSTANTS.STEPS.PURCHASE);
        } else {
            submitContext.submit();
        }
    }, [submitContext.setCustomer, submitContext.submit, submitContext.selectedBookingService, submitContext.setFiles]);

    const onSubmitBookingStep = useCallback((activeSlot) => {
        submitContext.setActiveSlot(activeSlot);
        setContactStepTitle(CONSTANTS.CONTACT_STEP_TITLES.AFTER_BOOKING);
        onSelectStep(CONSTANTS.STEPS.CONTACT);
    }, [submitContext, onSelectStep]);

    const onSubmitServiceStep = useCallback(() => {
        setContactStepTitle(CONSTANTS.CONTACT_STEP_TITLES.AFTER_BOOKING);
        if (step === CONSTANTS.STEPS.BOOKING_AND_SERVICE) {
            onSelectStep(CONSTANTS.STEPS.BOOKING);
        } else {
            onSelectStep(CONSTANTS.STEPS.CONTACT);
        }
    }, [submitContext, onSelectStep]);

    const isAsideWithLogo = useCallback(() => {
        return window.location.pathname !== `/${slugName}`;
    }, [window.location.pathname, slugName]);

    const onSkipPayment = useCallback(() => {
        submitContext.submit();
    }, [submitContext.submit])

    return (
        <div className={`main-page${isShowingHomeButton() ? ' main-page-with-home-button' : ''}`}>
            <Aside withLogo={isAsideWithLogo()}/>
            <main className='main-content'>
                {
                    isShowingHomeButton() && <HomeButton/>
                }
                <ServerDataProvider>
                    <ThemeProvider>
                        <InvoiceProvider>
                            <CompanyProvider>
                                <Routes>
                                    <Route path={`/${slugName}/contact`}
                                           element={<ContactStep onSubmit={onSubmitContactStep}
                                                                 contactStepTitle={contactStepTitle}/>}/>
                                    <Route path={`/${slugName}/booking`}
                                           element={<BookingStep onSubmit={onSubmitBookingStep}
                                                                 onSetContactMode={() => {
                                                                     onSelectStep(CONSTANTS.STEPS.CONTACT);
                                                                 }}/>}/>
                                    <Route path={`/${slugName}/service`}
                                           element={<ServiceStep onSubmit={onSubmitServiceStep}
                                                                 onSetContactMode={() => {
                                                                     onSelectStep(CONSTANTS.STEPS.CONTACT);
                                                                 }}/>}/>
                                    <Route path={`/${slugName}/service/category`}
                                           element={<ServiceCategoryStep onSetContactMode={() => {
                                               onSelectStep(CONSTANTS.STEPS.CONTACT);
                                           }}/>}/>
                                    <Route path={`/${slugName}/service/purchase`}
                                           element={<ServicePurchaseStep onSkipPayment={onSkipPayment}
                                                                         onPayment={() => {

                                                                         }}/>}/>
                                    <Route path={`/${slugName}`}
                                           element={<NotSelectedStep onSelectStep={onSelectStep}/>}/>
                                    <Route path={`/${slugName}/success`} element={<SuccessStep/>}/>
                                    <Route path="*" element={<NotSelectedStep onSelectStep={onSelectStep}/>}/>
                                </Routes>
                            </CompanyProvider>
                        </InvoiceProvider>
                    </ThemeProvider>
                </ServerDataProvider>
            </main>
        </div>
    );
};
