import React, {
    ChangeEvent,
    createRef,
    FunctionComponent,
    Ref,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import './ContactFileSection.scss';
import {Button} from "../../../components/ui-components/Button/Button";
import {ContactFileTile} from "./ContactFileTile/ContactFileTile";
import {CompanyContext} from "../../../contexts/CompanyContext";
import {CONSTANTS} from "../../../common/constants";
import {ToastService} from "../../../service/ToastService";

interface ContactFileSectionProps {
    onChangeFiles: (files: File[]) => void;
}

export const ContactFileSection: FunctionComponent<ContactFileSectionProps> = (props) => {
    const {company} = useContext(CompanyContext);
    const fileInput: Ref<HTMLInputElement> = createRef<HTMLInputElement>();
    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        props.onChangeFiles(files);
    }, [files, props.onChangeFiles]);

    const onChangeFileInput = (event: ChangeEvent) => {
        for (let i = 0; i < ((event.target as HTMLInputElement).files?.length || 0); i++) {
            files.push(((event.target as HTMLInputElement).files as FileList)[i]);
        }
        setFiles([...files]);
    }

    const chooseFiles = () => {
        fileInput.current?.click();
    }

    const onDeleteFile = (file: File) => {
        files.splice(files.indexOf(file), 1);
        setFiles([...files]);
    }

    const getAcceptTypes = useCallback(() => {
        //This is quick hotfix in production
        if (!company || company?.customer_portal_upload_file_restrictions?.length === 3) {
            return undefined;
        }
        return company.customer_portal_upload_file_restrictions.map((restriction) => {
            if (restriction === CONSTANTS.FILE_GROUPS.IMAGE) {
                return 'image/*';
            }
            if (restriction === CONSTANTS.FILE_GROUPS.VIDEO) {
                return 'video/*';
            }
            if (restriction === CONSTANTS.FILE_GROUPS.DOCUMENT) {
                return '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx';
            }
            return '';
        }).join(',');
    }, [company]);

    return (
        <section className='contact-file-section'>
            {
                (files && files.length > 0) && <h3 className='files-header'>Files: </h3>
            }

            <section className="contact-files-container">
                {files.map((file, index) => {
                    return <ContactFileTile key={index} file={file} onDeleteFile={onDeleteFile}/>
                })}
            </section>

            {
                company
                    ? (
                        <>
                            <Button className='secondary-button' type={'button'} onClick={chooseFiles}>
                                <i className="fad fa-plus left-button-icon"/>
                                {company.customer_portal_upload_file_display_name}
                            </Button>
                            <input accept={getAcceptTypes()} className='contact-file-hidden-input' ref={fileInput}
                                   type='file'
                                   multiple={true}
                                   onChange={onChangeFileInput}/>
                        </>
                    )
                    : null
            }

        </section>
    );
};
