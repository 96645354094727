import React, {useCallback, useState} from "react";

interface PreloaderContextState {
    isFullScreenPreloaderVisible: boolean;
    showFullScreenPreloader: () => void;
    hideFullScreenPreloader: () => void;
}

export const PreloaderContext = React.createContext({} as PreloaderContextState);

export const PreloaderProvider: React.FunctionComponent = ({children}) => {
    const [isFullScreenPreloaderVisible, setFullScreenPreloaderVisible] = useState<boolean>(false);

    const showFullScreenPreloader = useCallback(() => {
        setFullScreenPreloaderVisible(true);
    }, [setFullScreenPreloaderVisible]);

    const hideFullScreenPreloader = useCallback(() => {
        setFullScreenPreloaderVisible(false);
    }, [setFullScreenPreloaderVisible]);

    return (
        <PreloaderContext.Provider
            value={{isFullScreenPreloaderVisible, hideFullScreenPreloader, showFullScreenPreloader}}>
            {children}
        </PreloaderContext.Provider>
    )
}
