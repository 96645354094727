import React, {FunctionComponent, useEffect, useState} from 'react';
import {DayWithSlots} from "../WeekCalendar";
import "./WeekTimeline.scss"
import moment from "moment";


interface WeekTimelineProps {
    dayWithSlots: DayWithSlots[];
}

interface WeekTimelinePart {
    title: string;
    width: string;
    className: string;
}

export const WeekTimeline: FunctionComponent<WeekTimelineProps> = (props) => {
    const [timelineParts, setTimelineParts] = useState<WeekTimelinePart[]>([]);

    const getTimelineTitle = (day: DayWithSlots) => {
        const todayWeek = moment().isoWeek();
        const dayWeek = day.day.isoWeek();
        if (todayWeek === dayWeek) {
            return 'this week';
        } else if (todayWeek + 1 === dayWeek) {
            return 'next week';
        } else if (dayWeek > todayWeek) {
            return `${dayWeek - todayWeek} weeks out`;
        }
        return '';
    }

    useEffect(() => {
        const firstDay = props.dayWithSlots[0];
        let daysInFirstWeek = 0;
        for (let day of props.dayWithSlots) {
            if (firstDay.day.isoWeek() === day.day.isoWeek()) {
                daysInFirstWeek++;
            }
        }
        if (daysInFirstWeek === props.dayWithSlots.length) {
            setTimelineParts([{
                title: getTimelineTitle(firstDay),
                width: '100%',
                className: 'timeline-part single-timeline-part'
            }]);
        } else {
            const firstPartWidth = Math.ceil(daysInFirstWeek / props.dayWithSlots.length * 100);
            const secondPartWidth = 100 - firstPartWidth;
            const secondWeekDay = props.dayWithSlots[daysInFirstWeek];
            setTimelineParts([{
                title: getTimelineTitle(firstDay),
                width: `${firstPartWidth}%`,
                className: 'timeline-part first-timeline-part'
            }, {
                title: getTimelineTitle(secondWeekDay),
                width: `${secondPartWidth}%`,
                className: 'timeline-part second-timeline-part'
            }]);
        }
    }, [props.dayWithSlots]);

    return (
        <div className='week-timeline'>
            {
                timelineParts.map((timelinePart, index: number) => {
                    return (<div key={index} className={timelinePart.className}
                                 style={{'width': timelinePart.width}}>
                        <div className="border-element"/>
                        <p className='timeline-part-text'>
                            <span>
                                {timelinePart.title}
                            </span>
                        </p>
                    </div>)
                })
            }
        </div>
    );
};
