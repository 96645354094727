import {SETTINGS} from "../common/settings";
import moment, {Moment} from "moment";
import {DaySlot} from "../models/day-slot";
import {DayWithSlots} from "../components/WeekCalendar/WeekCalendar";

export const getDaySlotsInformation = async (companyID: number, days: Moment[], bookingServiceId: number | null = null) => {
    const startTime = days[0].clone().startOf('day');
    const endTime = days[days.length - 1].clone().endOf('day');
    let requestUrl = `${SETTINGS.getApiUrl()}/portal/company/slots/${companyID}?limit=100000&start=${startTime.unix()}&end=${endTime.unix()}`;
    if (bookingServiceId) {
        requestUrl += `&service_id=${bookingServiceId}`;
    }
    return fetch(requestUrl, {method: 'GET'})
        .then((response) => {
            return response.json();
        })
        .then((response: { response: { slots: DaySlot[] }, error: boolean, errors?: string[] }) => {
            if (response.error) {
                throw new Error((response.errors || []).join('\n'));
            }
            const dayWithSlots: DayWithSlots[] = [];
            for (const day of days) {
                dayWithSlots.push({day, slots: []});
            }
            const today = moment();
            if (Array.isArray(response.response.slots)) {
                for (const slot of response.response.slots) {
                    const slotStartTime = moment(moment.unix(slot.start_timestamp));
                    const slotEndTime = moment(moment.unix(slot.start_timestamp));
                    slot.endTime = slotEndTime;
                    slot.startTime = slotStartTime;
                    if (slotStartTime.isBefore(today)) {
                        continue;
                    }
                    for (const dayWithSlot of dayWithSlots) {
                        if (dayWithSlot.day.isSame(slotStartTime, 'date')) {
                            dayWithSlot.slots.push(slot);
                        }
                    }
                }
            } else {
                for (const day of Object.keys(response.response.slots)) {
                    for (const timestamp of Object.keys(response.response.slots[day])) {
                        const responseSlot = response.response.slots[day][timestamp] as { start_time: string, end_time: string, user_combinations: any };
                        const slotStartTime = moment(moment.utc(responseSlot.start_time).toDate());
                        const slotEndTime = moment(moment.utc(responseSlot.end_time).toDate());
                        const slot: DaySlot = {
                            startTime: slotStartTime,
                            endTime: slotEndTime,
                            start_timestamp: slotStartTime.unix(),
                            end_timestamp: slotEndTime.unix(),
                            every_minute: 0,
                            user_combinations: responseSlot.user_combinations
                        };
                        if (slotStartTime.isBefore(today)) {
                            continue;
                        }
                        for (const dayWithSlot of dayWithSlots) {
                            if (dayWithSlot.day.isSame(slotStartTime, 'date')) {
                                dayWithSlot.slots.push(slot);
                            }
                        }
                    }
                }
            }
            return dayWithSlots;
        })
}
