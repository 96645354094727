import React, {useEffect, useState} from "react";

interface SlugContextState {
    slugName: string;
}

export const SlugContext = React.createContext({} as SlugContextState);

export const SlugProvider: React.FunctionComponent = ({children}) => {
    const [slugName, setSlugName] = useState<string>('');

    useEffect(() => {
        const slugNameRegex = window.location.pathname.match(/^\/(\w*).*/);
        if(slugNameRegex && slugNameRegex[1]) {
            setSlugName(slugNameRegex[1]);
        }
    }, []);

    return (
        <SlugContext.Provider value={{slugName}}>
            {children}
        </SlugContext.Provider>
    )
}
