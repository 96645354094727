import {SETTINGS} from "../common/settings";
import {Company} from "../models/company";
import {Customer} from "../models/customer";
import {DaySlot} from "../models/day-slot";
import moment from "moment-timezone";
import {CustomField} from "../models/custom-field";
import {BookingService} from "../models/service";

export const getCompanyInformation = async (slugName: string) => {
    return fetch(`${SETTINGS.getApiUrl()}/portal/company/${slugName}`, {method: 'GET'})
        .then((response) => {
            return response.json();
        })
        .then((response: { response: { company: Company }, error: boolean, errors?: string[] }) => {
            if (response.error) {
                throw new Error((response.errors || []).join('\n'));
            }
            return response?.response?.company as Company;
        });
}

export const sendContactRequest = async (companyID: number, customer: Customer, daySlot?: DaySlot, customFields?: CustomField[], selectedBookingService?: BookingService, paymentInformation?: any) => {
    let request: any = {
        full_name: customer.full_name,
        email: customer.email,
        phone: customer.phone,
        description: customer.description,
        timezone: moment.tz.guess(),
        address: customer.address,
        city: customer.city,
        state: customer.state,
        zip_code: customer.zip_code,
    };
    if (daySlot) {
        request['start_date_time'] = daySlot.start_timestamp;
        request['end_date_time'] = daySlot.end_timestamp;
    }
    if (customFields?.length) {
        request['custom_fields'] = customFields.map((customField) => {
            return {
                custom_field_id: customField.id,
                custom_field_title: customField.title,
                value: customField.value
            }
        })
    }
    if (selectedBookingService) {
        request['service_id'] = selectedBookingService.id;
        request['attach_invoice'] = true;
        if(daySlot && daySlot.user_combinations) {
            request['user_combinations'] = daySlot.user_combinations;
        }
    }

    if (paymentInformation) {
        request['payment'] = paymentInformation;
    }
    return fetch(`${SETTINGS.getApiUrl()}/portal/request/${companyID}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {'Content-Type': 'application/json'}
    })
        .then((response) => {
            return response.json();
        })
        .then((response: { response: { id: number, start_date_time: number, end_date_time: number }, portal_booked_slot: { id: number, start_date_time: number, end_date_time: number }, error: boolean, errors?: string[] }) => {
            if (response.error) {
                throw new Error((response.errors || []).join('\n'));
            }
            const portalBookedSlot = response.portal_booked_slot || response.response;
            if (portalBookedSlot.start_date_time && daySlot) {
                daySlot.startTime = moment(moment.utc(portalBookedSlot.start_date_time).toDate())
                daySlot.endTime = moment(moment.utc(portalBookedSlot.end_date_time).toDate())
            }
            return {daySlot: daySlot, id: portalBookedSlot.id};
        });
}


export const sendContactFileRequest = async (companyID: number, requestID: number, files: File[]) => {
    const body = new FormData();
    for (const file of files) {
        body.append('files[]', file, file.name);
    }
    return fetch(`${SETTINGS.getApiUrl()}/portal/request/${companyID}/file/${requestID}`, {
        method: 'POST',
        body: body,
    })
        .then((response) => {
            return response.json();
        })
        .then(() => {

        });
}
