import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import moment, {Moment} from "moment";
import {DaySlot} from "../../models/day-slot";
import {WeekDaySlots} from "./WeekDaySlots/WeekDaySlots";
import {WeekDay} from "./WeekDay/WeekDay";
import {WeekTimeline} from "./WeekTimeline/WeekTimeline";
import {MonthCalendar} from "../MonthCalendar/MonthCalendar";
import './WeekCalendar.scss';
import {Preloader} from "../Preloader/Preloader";
import {Button} from "../ui-components/Button/Button";

export interface DayWithSlots {
    day: Moment;
    slots: DaySlot[];
}

interface WeekCalendarProps {
    dayWithSlots: DayWithSlots[];
    activeDay: DayWithSlots;
    activeWeekDaySlot: DaySlot | null;
    onSetActiveDay: (dayWithSlot: DayWithSlots) => void;
    onSetActiveSlot: (slot: DaySlot) => void;
    onSetContactMode: () => void;
    onGoToNewDay: (day: Moment) => void;
}

export const WeekCalendar: FunctionComponent<WeekCalendarProps> = (props) => {
    const [isMonthCalendarVisible, setMonthCalendarVisible] = useState<boolean>(false);

    useEffect(() => {
        if (isMonthCalendarVisible) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }

    }, [isMonthCalendarVisible]);

    const setActiveDay = (dayWithSlot: DayWithSlots) => {
        if (dayWithSlot.slots.length) {
            props.onSetActiveDay(dayWithSlot);
        }
    }

    const setContactMode = () => {
        props.onSetContactMode();
    }

    const setActiveSlot = (slot: DaySlot) => {
        props.onSetActiveSlot(slot);
    }

    const getLeftLeverClasses = useCallback(() => {
        const classes: string[] = ['week-calendar-lever left-week-calendar-lever'];
        if (props.dayWithSlots[0].day.isSameOrBefore(moment(), 'date')) {
            classes.push('inactive');
        }
        return classes.join(' ');
    }, [props.dayWithSlots]);

    const goToPreviousWeek = () => {
        let newDay = props.dayWithSlots[0].day.clone().subtract(1, 'week');
        if (newDay.isBefore(moment())) {
            newDay = moment().clone();
        }
        props.onGoToNewDay(newDay);
    }

    const goToNextWeek = () => {
        const newDay = props.dayWithSlots[0].day.clone().add(1, 'week');
        props.onGoToNewDay(newDay);
    }

    const openMonthCalendar = () => {
        setMonthCalendarVisible(true);
    }

    const closeMonthCalendar = () => {
        setMonthCalendarVisible(false);
    }

    const setMonthDay = (day: DayWithSlots) => {
        props.onGoToNewDay(day.day);
    }

    return (
        <div className='week-calendar'>
            {
                props.dayWithSlots?.length
                    ? <div>
                        <section className="week-calendar-container">
                            <i onClick={goToPreviousWeek}
                               className={`fal fa-3x fa-angle-left calendar-action-button ${getLeftLeverClasses()}`}/>

                            <section className='week-calendar-days-container'>
                                {props.dayWithSlots.map((dayWithSlot, index: number) => {
                                    return <WeekDay isActive={dayWithSlot === props.activeDay}
                                                    onSetActiveDay={setActiveDay}
                                                    key={index} dayWithSlot={dayWithSlot}/>
                                })}
                            </section>

                            <i onClick={goToNextWeek}
                               className="fal fa-3x fa-angle-right calendar-action-button week-calendar-lever right-week-calendar-lever"/>
                        </section>

                        <WeekTimeline dayWithSlots={props.dayWithSlots}/>

                        <div className="month-calendar-container">
                            <Button className='secondary-button' onClick={openMonthCalendar}>
                                <i className="fad fa-calendar-alt left-button-icon"/>
                                Select Date On Calendar
                            </Button>

                            {
                                isMonthCalendarVisible
                                && <MonthCalendar activeDay={props.activeDay} onSetActiveDay={setMonthDay}
                                                  onClose={closeMonthCalendar}/>
                            }
                        </div>

                        {
                            props?.activeDay?.slots?.length
                                ? <WeekDaySlots slots={props.activeDay.slots} activeWeekDaySlot={props.activeWeekDaySlot}
                                                onSetActiveSlot={setActiveSlot}/>
                                : null
                        }

                        <p className='link-text' onClick={setContactMode}>Can't find a timeslot? Send us a
                            message</p>
                    </div>
                    : <div className='preloader-container'>
                        <Preloader/>
                    </div>
            }
        </div>
    );
};
