import React, {ForwardRefRenderFunction} from 'react';
import styled from "styled-components";
import {CONSTANTS} from "../../../common/constants";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
}

const StyledInput = styled.input`
  &&& {
    height: ${CONSTANTS.UI_PARAMS.INPUT_HEIGHT}px;
    min-width: ${CONSTANTS.UI_PARAMS.INPUT_MIN_WIDTH}px;
    border-radius: ${CONSTANTS.UI_PARAMS.INPUT_BORDER_RADIUS}px;
    border: 1px solid ${CONSTANTS.UI_PARAMS.DIVIDER_COLOR}
  }
`;


export const Input = React.forwardRef<ForwardRefRenderFunction<HTMLInputElement>, InputProps>((props, ref: any) => {
        return (
            <StyledInput {...props} className={'input'}/>
        );
    }
)
