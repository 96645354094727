import React, {FunctionComponent} from 'react';
import './CustomFieldInput.scss';
import {CustomField} from "../../../models/custom-field";
import {Input} from "../../../components/ui-components/Input/Input";


interface CustomFieldInputProps {
    customField: CustomField;
    onChange: (value: string) => void;
}

export const CustomFieldInput: FunctionComponent<CustomFieldInputProps> = (props) => {
    return (
        <div className="input-container">
            <label htmlFor={'custom-field' + props.customField.id}>{props.customField.title}</label>
            <Input id={'custom-field' + props.customField.id} type="text" name={props.customField.title}
                   onChange={(event) => {
                       props.onChange(event.target.value);
                   }}/>
        </div>
    )
};
