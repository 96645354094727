import React, {FunctionComponent, useContext} from 'react';
import {PreloaderContext} from "../../contexts/PreloaderContext";
import './FullScreenPreloader.scss';

export const FullScreenPreloader: FunctionComponent = (props) => {
    const preloaderContext = useContext(PreloaderContext);

    return (
        preloaderContext.isFullScreenPreloaderVisible
            ? <div className='full-screen-preloader'>
                <img src="/assets/images/preloader.svg"/>
            </div>
            : null
    );
};
