import React, {FunctionComponent, useContext} from 'react';
import './SuccessStep.scss';
import {SubmitContext} from "../../contexts/SubmitContext";
import {CompanyContext} from "../../contexts/CompanyContext";
import {GoogleContext} from "../../contexts/GoogleContext";
import {saveAs} from "file-saver";

interface SuccessStepProps {
}

export const SuccessStep: FunctionComponent<SuccessStepProps> = ((props) => {
    const submitContext = useContext(SubmitContext);
    const company = useContext(CompanyContext).company;
    const googleContext = useContext(GoogleContext);

    const exportToIcal = () => {
        if (Blob) {
            const icalData = 'BEGIN:VCALENDAR\n' +
                'PRODID:-\n' +
                'BEGIN:VEVENT\n' +
                'SUMMARY:' + company?.title + '\n' +
                'DTSTART:' + submitContext.activeSlot?.startTime.format('YYYYMMDDTHHmmss') + '\n' +
                'DTEND:' + submitContext.activeSlot?.endTime.format('YYYYMMDDTHHmmss') + '\n' +
                'END:VEVENT\n' +
                'END:VCALENDAR';
            const blob = new Blob([icalData], {type: 'text/plain;charset=utf-8'});
            saveAs(blob, 'fieldpulse.ics');
        }
    }

    const exportToGoogleCalendar = () => {
        googleContext.addEventToGoogleCalendar();
    }

    const renderSuccessPageWithTime = () => {
        return (<>
                <h1>
                    <i className="fad fa-check check-icon"/>
                    You have successfully scheduled a visit
                </h1>
                <h3>Thank you for scheduling an appointment. {company?.portal_email_customer_confirmation && <>We have
                    sent you an email with
                    details.</>}</h3>

                {renderDateInfoSection()}
            </>
        )
    }

    const renderDateInfoSection = () => {
        return <>
            <div className="date-info">
                <p>
                    Date: {submitContext.activeSlot?.startTime.format('MM/DD/YYYY')}
                </p>
                <p>
                    Time: {submitContext.activeSlot?.startTime.format('h:mm A')}
                </p>
            </div>

            <h3 className="light">You can add an appointment to your calendar with buttons below:</h3>

            <div className="export-buttons">
                <button className="image-button" onClick={exportToIcal}>
                    <span>iCal</span>
                    <img src="/assets/images/ical_icon.png" alt="iCal"/>
                </button>
                <span className="text-divider">or</span>
                <button className="image-button" onClick={exportToGoogleCalendar}>
                    <span>Google Calendar</span>
                    <img src="/assets/images/google_calendar_icon.png" alt="google calendar"/>
                </button>
            </div>
        </>
    }

    const renderSuccessPageWithContact = () => {
        return (<div>
            <h1><i className="fad fa-check check-icon"/>You have successfully requested a contact</h1>
            <h3>Thank you for requesting a contact. {company?.portal_email_customer_confirmation && <>We have
                    sent you an email with
                    details.</>}</h3>
        </div>)
    }

    const renderServiceStep = () => {
        return (<>
                {submitContext.paymentInformation ? <h1>
                    <i className="fad fa-check check-icon"/>
                    You have successfully purchased
                    service '{submitContext.selectedBookingService?.title}'
                    for {company?.default_currency_object.symbol_native}{submitContext.paymentInformation?.amount}
                </h1> : <h1>
                    <i className="fad fa-check check-icon"/>
                    You have successfully ordered service '{submitContext.selectedBookingService?.title}'
                </h1>}
                <h3>{company?.portal_email_customer_confirmation && <>We have
                    sent you an email with
                    details.</>}</h3>
            </>
        )
    }

    const renderBookingAndServiceStep = () => {
        const serviceMessage = submitContext.paymentInformation ? `purchased
            service '${submitContext.selectedBookingService?.title}'
            for ${company?.default_currency_object.symbol_native}${submitContext.paymentInformation?.amount}` :
            `ordered service '${submitContext.selectedBookingService?.title}`;

        return (<>
                <h1>
                    <i className="fad fa-check check-icon"/>
                    You have successfully scheduled a visit and {serviceMessage}
                </h1>
                <h3>{company?.portal_email_customer_confirmation && <>We have
                    sent you an email with
                    details.</>}</h3>

                {renderDateInfoSection()}
            </>
        )
    }

    const renderSuccessPage = () => {
        if (submitContext.activeSlot && submitContext.selectedBookingService) {
            return renderBookingAndServiceStep();
        } else if (submitContext.activeSlot) {
            return renderSuccessPageWithTime();
        } else if (submitContext.selectedBookingService) {
            return renderServiceStep();
        } else {
            return renderSuccessPageWithContact();
        }
    }

    return (
        <section className='step-container success-step-container not-selected-step-container'>
            {renderSuccessPage()}
        </section>
    )
});
