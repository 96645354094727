import React, {FunctionComponent, useContext} from 'react';
import {CompanyContext} from "../../contexts/CompanyContext";
import './Aside.scss';
import {CONSTANTS} from "../../common/constants";

interface AsideProps {
    withLogo: boolean;
}

export const Aside: FunctionComponent<AsideProps> = (props) => {
    const company = useContext(CompanyContext).company;

    const isShortDescription = () => company && (company?.customer_portal_company_description.length <= CONSTANTS.SHORT_DESCRIPTION_MAX_NUMBER_OF_SYMBOLS);

    return (
        <aside className={`main-aside${isShortDescription() ? ' main-aside-with-short-description' : ''}`}>

            {
                props.withLogo && company?.logo?.url && <section className="logo-section">
                    <img className='main-aside-logo' alt='logo' src={company?.logo?.url}/>
                </section>
            }


            {
                company?.customer_portal_company_description && <section className='company-description-section'>
                    <p>{company?.customer_portal_company_description}</p>
                </section>
            }

            <section className='company-address-section'>
                {company?.address && <div>{company.address}</div>}

                <div>{[company?.city, company?.state, company?.zip_code].filter((value: string | undefined) => value).join(', ')}</div>

                {company?.website_url && <div><a href={company.website_url} target="_blank">{company.website_url}</a></div>}

                {company?.phone && <div>{company.phone}</div>}
            </section>
        </aside>
    );
};
