import {CONSTANTS} from "../common/constants";

const DESKTOP_MIN_WIDTH = 1024;
const TABLET_MIN_WIDTH = 768;
const TABLET_MAX_WIDTH = 1023
const MOBILE_MAX_WIDTH = 767;

function getScreenWidth(): number {
    return (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
}

function isDesktopDevice(): boolean {
    return getScreenWidth() >= DESKTOP_MIN_WIDTH;
}

function isTabletDevice(): boolean {
    let screenWidth = getScreenWidth();

    return screenWidth >= TABLET_MIN_WIDTH && screenWidth <= TABLET_MAX_WIDTH;
}

function isMobileDevice(): boolean {
    return getScreenWidth() <= MOBILE_MAX_WIDTH;
}

export const UTIL_FUNCTIONS = {
    isMobileDevice,
    isTabletDevice,
    isDesktopDevice,
    getScreenWidth
};

function detectIphone(): any {
    return window.navigator.userAgent.indexOf('iPhone') !== -1;
}

function detectIpad(): any {
    return window.navigator.userAgent.indexOf('iPad') !== -1 || (window.navigator.userAgent.indexOf('Safari') !== -1 && 'ontouchstart' in window && window.navigator.maxTouchPoints > 1);
}

function detectAndroid(): any {
    return window.navigator.userAgent.indexOf('Android') !== -1;
}

const a = CONSTANTS.BROWSERS;
export function detectBrowser(): any  {
    let browser = CONSTANTS.BROWSERS.Unknown;
    let userAgent = navigator.userAgent;

    //The order matters here, and this may report false positives for unlisted browsers.
    if (userAgent.indexOf("Firefox") > -1) {
        browser = CONSTANTS.BROWSERS.Firefox;
        //"Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (userAgent.indexOf("Opera") > -1) {
        browser = CONSTANTS.BROWSERS.Opera;
    } else if (userAgent.indexOf("Trident") > -1) {
        browser = CONSTANTS.BROWSERS.IE;
        //"Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (userAgent.indexOf("Edge") > -1) {
        browser = CONSTANTS.BROWSERS.Edge;
        //"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (userAgent.indexOf("Chrome") > -1) {
        browser = CONSTANTS.BROWSERS.Chrome;
        //"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (userAgent.indexOf("Safari") > -1) {
        browser = CONSTANTS.BROWSERS.Safari;
        //"Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    }

    return browser;
}

export function detectMobileDevice() {
    return detectAndroid() || detectIpad() || detectIphone();
}
