import {FunctionComponent, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "../ui-components/Button/Button";
import {SlugContext} from "../../contexts/SlugContext";
import './HomeButton.scss';

export const HomeButton: FunctionComponent = () => {
    const navigate = useNavigate();
    const slugName = useContext(SlugContext).slugName;

    const navigateHome = () => {
        navigate(`/${slugName}`);
    };

    return (
        <div className='home-button-container'>
            <Button id='home-button' className='secondary-button' onClick={navigateHome}>
                <i className="fad fa-home left-button-icon"/>
                Back To The Main Page
            </Button>
        </div>
    );
};