import {FunctionComponent} from "react";


interface PreloaderProps {
    width?: string;
    height?: string;
}

export const Preloader: FunctionComponent<PreloaderProps> = (props) => {
    const preloaderStyles = {
        width: props.width || '75px',
        height: props.height || '75px'
    }

    return <img style={preloaderStyles} src="/assets/images/preloader.svg" alt="preloader"/>;
};