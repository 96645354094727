enum BROWSERS {
    Firefox, Opera, IE, Edge, Chrome, Safari, Unknown
}

const UI_PARAMS = {
    INPUT_HEIGHT: 40,
    INPUT_MOBILE_HEIGHT: 60,
    INPUT_MIN_WIDTH: 110,
    INPUT_BORDER_RADIUS: 5,
    FP_BRANDING_COLOR: '#00034d',
    DIVIDER_COLOR: '#cbcbcb',
    TEXT_COLOR: '#1c1c1c'
};

enum BOOKING_PORTAL_MODES {
    FORM = 1,
    SCHEDULE = 2,
    SERVICE = 3,
    SCHEDULE_AND_SERVICE = 4,
    SCHEDULE_ONLY = 5
}

enum STEPS {
    CONTACT,
    BOOKING,
    SERVICE,
    BOOKING_AND_SERVICE,
    PURCHASE
}

const BROWSER_BREAKPOINTS: { [key: string]: string } = {
    DESKTOP: "(min-width: 1024px)",
    TABLET: "(min-width: 768px) and (max-width: 1023px)",
    MOBILE: "(max-width: 767px)",
    MOBILE_AND_TABLET: "(max-width: 1023px)"
};

enum CONTACT_STEP_TITLES {
    DEFAULT = 'Contact Us',
    AFTER_BOOKING = 'Please fill out the form'
}

enum BOOKING_SERVICE_METHODS {
    NO_PRICING_INFORMATION = 'no_pricing_information',
    NO_PAYMENTS = 'no_payment_taken',
    DEPOSIT_PAYMENT = 'require_deposit',
    FULL_PAYMENT = 'require_full_payment'
}

enum FILE_GROUPS {
    DOCUMENT = 'document',
    IMAGE = 'image',
    VIDEO = 'video'
}

const FILE_GROUP_NAMES = Object.values(FILE_GROUPS);

const FILE_EXTENSIONS_BY_GROUP: { [key: string]: string[] } = {
    [FILE_GROUPS.DOCUMENT]: [
        // Presentations
        '.key', '.odp', '.pps', '.ppt', '.pptx',
        // Spreadsheet
        '.ods', '.xls', '.xlsm', '.xlsx',
        //Word and text
        '.doc', '.docx', '.odt', '.rtf', '.tex', '.txt', '.wpd',
        //PDF
        '.pdf'
    ],
    [FILE_GROUPS.IMAGE]: [
        '.tif', '.tiff', '.bmp', '.jpg', '.jpeg', '.gif', '.png', '.ico'
    ],
    [FILE_GROUPS.VIDEO]: [
        '.3g2', '.3gp', '.avi', '.flv', '.h264', '.m4v', '.mkv', '.mov', '.mp4',
        '.mpg', '.mpeg', '.swf', '.wmv'
    ]
};

export class CONSTANTS {
    public static BROWSERS = BROWSERS;
    public static UI_PARAMS = UI_PARAMS;
    public static BOOKING_PORTAL_MODES = BOOKING_PORTAL_MODES;
    public static STEPS = STEPS;
    public static BROWSER_BREAKPOINTS = BROWSER_BREAKPOINTS;
    public static DAY_IN_WEEK = 7;
    public static UNKNOWN_ERROR = 'Error: Please try again or contact FieldPulse support.';
    public static CONTACT_STEP_TITLES = CONTACT_STEP_TITLES;
    public static BOOKING_SERVICE_METHODS = BOOKING_SERVICE_METHODS;
    public static SHORT_DESCRIPTION_MAX_NUMBER_OF_SYMBOLS = 250;
    public static FILE_GROUPS = FILE_GROUPS;
    public static FILE_EXTENSIONS_BY_GROUP = FILE_EXTENSIONS_BY_GROUP;
    public static FILE_GROUP_NAMES = FILE_GROUP_NAMES;
}
