import React, {createRef, FunctionComponent, Ref, useCallback, useContext, useState} from 'react';
import {Input} from "../../components/ui-components/Input/Input";
import {Textarea} from "../../components/ui-components/Textarea/Textarea";
import {Button} from "../../components/ui-components/Button/Button";
import {Customer} from "../../models/customer";
import moment from "moment-timezone";
import './ContactStep.scss';
import {CONSTANTS} from "../../common/constants";
import {CustomFieldContext} from "../../contexts/CustomFieldContext";
import {CustomField} from "../../models/custom-field";
import {CustomFieldInput} from "./CustomFieldInput/CustomFieldInput";
import {CompanyContext} from "../../contexts/CompanyContext";
import {ContactFileSection} from "./ContactFileSection/ContactFileSection";


interface ContactStepProps {
    onSubmit: (customer: Customer, customFields: CustomField[], files: File[]) => void;
    contactStepTitle?: string | null;
}

export const ContactStep: FunctionComponent<ContactStepProps> = (props) => {
    const [customerName, setCustomerName] = useState<string>('');
    const [customerEmail, setCustomerEmail] = useState<string>('');
    const [customerPhone, setCustomerPhone] = useState<string>('');
    const [customerAddress, setCustomerAddress] = useState<string>('');
    const [customerState, setCustomerState] = useState<string>('');
    const [customerCity, setCustomerCity] = useState<string>('');
    const [customerZipCode, setCustomerZipCode] = useState<string>('');
    const [customerMessage, setCustomerMessage] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const customFields = useContext(CustomFieldContext).customFields;
    const contactStepTitle = props.contactStepTitle || CONSTANTS.CONTACT_STEP_TITLES.DEFAULT;
    const contactForm: Ref<HTMLFormElement> = createRef();
    const companyContext = useContext(CompanyContext);

    const submitContactForm = (event: React.MouseEvent) => {
        const isFormValid = contactForm.current?.checkValidity();
        if (!isFormValid) {
            return;
        }
        const customer: Customer = {
            full_name: customerName,
            address: customerAddress,
            description: customerMessage,
            email: customerEmail,
            phone: customerPhone,
            state: customerState,
            city: customerCity,
            zip_code: customerZipCode,
            timezone: moment.tz.guess()
        };
        props.onSubmit(customer, customFields, files);
        return false;
    }

    const getZipName = useCallback(() => {
        if (companyContext.isAuNZCompany()) {
            return 'Postcode';
        }
        return 'Zip';
    }, [companyContext]);

    return (
        <section className='step-container'>
            <h1>{contactStepTitle}</h1>

            <section className="contact-form-container">
                <form ref={contactForm} onSubmit={(event) => {
                    event.preventDefault();
                }}>
                    <div className="input-container">
                        <label htmlFor="name">Name</label>
                        <Input required id="name" type="text" name="name"
                               onChange={(event) => {
                                   setCustomerName(event.target.value);
                               }}/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="email">Email</label>
                        <Input required id="email" type="email" name="email" onChange={(event) => {
                            setCustomerEmail(event.target.value);
                        }}/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="phone">Phone Number</label>
                        <Input required id="phone" type="text" name="phone"
                               onChange={(event) => {
                                   setCustomerPhone(event.target.value);
                               }}/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="address">Address</label>
                        <Input required id="address" type="text" name="address"
                               onChange={(event) => {
                                   setCustomerAddress(event.target.value);
                               }}/>
                    </div>
                    <div className="address-inputs-container">
                        <div className="input-container">
                            <label htmlFor="city">City</label>
                            <Input required id="city" type="text" name="city"
                                   onChange={(event) => {
                                       setCustomerCity(event.target.value);
                                   }}/>
                        </div>
                        <div className="input-container">
                            <label htmlFor="state">State</label>
                            <Input required id="state" type="text" name="state"
                                   onChange={(event) => {
                                       setCustomerState(event.target.value);
                                   }}/>
                        </div>
                        <div className="input-container">
                            <label htmlFor="zip">{getZipName()}</label>
                            <Input required id="zip" type="text" name="zip"
                                   onChange={(event) => {
                                       setCustomerZipCode(event.target.value);
                                   }}/>
                        </div>
                    </div>
                    {customFields.map((customField: CustomField) => {
                            return <CustomFieldInput key={customField.id} customField={customField}
                                                     onChange={(value: string) => {
                                                         customField.value = value;
                                                     }}/>
                        }
                    )}
                    <div className="input-container">
                        <label htmlFor="message">Message</label>
                        <Textarea minLength={6} required name="message" id="message" rows={4} onChange={(event) => {
                            setCustomerMessage(event.target.value);
                        }}/>
                    </div>

                    <ContactFileSection onChangeFiles={setFiles}/>

                    <div className="buttons">
                        <Button type="submit" onClick={submitContactForm}>Submit</Button>
                    </div>
                </form>
            </section>
        </section>
    );
};
