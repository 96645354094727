import React, {useCallback, useContext, useEffect, useState} from "react";
import {DaySlot} from "../models/day-slot";
import {Customer} from "../models/customer";
import {PreloaderContext} from "./PreloaderContext";
import {sendContactFileRequest, sendContactRequest} from "../api/company.api";
import {CompanyContext} from "./CompanyContext";
import {useNavigate} from "react-router-dom";
import {SlugContext} from "./SlugContext";
import {ToastService} from "../service/ToastService";
import {CONSTANTS} from "../common/constants";
import {BookingService} from "../models/service";
import {CustomField} from "../models/custom-field";

interface SubmitContextState {
    setActiveSlot: (slot: DaySlot | null) => void;
    setCustomer: (customer: Customer) => void;
    submit: () => void;
    isValid: () => boolean;
    customer: Customer | null;
    activeSlot: DaySlot | null;
    setSelectedBookingService: (selectedBookingService: BookingService) => void;
    selectedBookingService: BookingService | null;
    setPaymentInformation: (paymentInformation: any) => void;
    paymentInformation: any | null;
    setCustomFields: (customFields: CustomField[]) => void;
    setFiles: (files: File[]) => void;
}

export const SubmitContext = React.createContext({} as SubmitContextState);

export const SubmitProvider: React.FunctionComponent = ({children}) => {
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [activeSlot, setActiveSlot] = useState<DaySlot | null>(null);
    const [selectedBookingService, setSelectedBookingService] = useState<BookingService | null>(null);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);
    const [paymentInformation, setPaymentInformation] = useState<any>(null);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const navigate = useNavigate();
    const preloaderContext = useContext(PreloaderContext);
    const company = useContext(CompanyContext).company;
    const slugName = useContext(SlugContext).slugName;

    const sendRequest = useCallback(async () => {
        preloaderContext.showFullScreenPreloader();
        const customFieldsToSend = customFields.filter((customField) => {
            return customField.value?.length;
        })
        const response = await sendContactRequest(company?.id as number, customer as Customer, activeSlot as DaySlot, customFieldsToSend, selectedBookingService as BookingService, paymentInformation)
            .catch((error: Error) => {
                if (error && error.message) {
                    ToastService.middleScreenErrorToast(error.message);
                } else {
                    ToastService.middleScreenErrorToast(CONSTANTS.UNKNOWN_ERROR);
                }
            });
        if (response && response.id) {
            const fileResponse = await sendContactFileRequest(company?.id as number, response.id, files)
                .catch(() => {

                })
        }
        setSubmitted(false);
        preloaderContext.hideFullScreenPreloader();
        if (response) {
            navigate(`${slugName}/success`);
        }
    }, [customer, activeSlot, setSubmitted, preloaderContext, company, customFields, selectedBookingService, navigate, slugName]);

    useEffect(() => {
        if (isSubmitted && customer) {
            sendRequest();
        }
    }, [customer, isSubmitted, activeSlot, sendRequest]);

    const submit = useCallback(() => {
        preloaderContext.showFullScreenPreloader();
        setSubmitted(true);
    }, [preloaderContext, setSubmitted]);

    const isValid = () => {
        return customer !== null;
    }

    return (
        <SubmitContext.Provider
            value={{
                setActiveSlot,
                setCustomer,
                submit,
                isValid,
                customer,
                activeSlot,
                setSelectedBookingService,
                selectedBookingService,
                setPaymentInformation,
                paymentInformation,
                setCustomFields,
                setFiles
            }}>{children}
        </SubmitContext.Provider>)
}
