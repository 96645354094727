import {SETTINGS} from "../common/settings";
import {BookingServiceCategory} from "../models/service-category";

export const getBookingServiceInformation = async (companyID: number) => {
    return fetch(`${SETTINGS.getApiUrl()}/portal/company/services/${companyID}`, {method: 'GET'})
        .then((response) => {
            return response.json();
        })
        .then((response: { response: BookingServiceCategory[], error: boolean, errors?: string[] }) => {
            if (response.error) {
                throw new Error((response.errors || []).join('\n'));
            }
            return response.response;
        })
}