import React, {FunctionComponent, useCallback, useContext, useEffect} from 'react';
import './ServiceStep.scss';
import {ServiceContext} from "../../contexts/ServiceContext";
import {BookingServiceTile} from "../../components/BookingService/BookingService";
import {useNavigate} from "react-router-dom";
import {SlugContext} from "../../contexts/SlugContext";
import {Button} from "../../components/ui-components/Button/Button";


interface ServiceStepProps {
    onSubmit: () => void;
    onSetContactMode: () => void;
}

export const ServiceStep: FunctionComponent<ServiceStepProps> = (props) => {
    const services = useContext(ServiceContext).selectedCategory?.booking_portal_services;
    const navigate = useNavigate();
    const slugName = useContext(SlugContext).slugName;

    useEffect(() => {
        if (!services || services.length === 0) {
            navigate(`/${slugName}/service/category`);
        }
    }, [services]);

    const setContactMode = useCallback(() => {
        props.onSetContactMode();
    }, [props.onSetContactMode]);

    const goBackToCategories = useCallback(() => {
        navigate(`/${slugName}/service/category`);
    }, [navigate]);

    return <section className='step-container service-step'>
        <header>
            <Button onClick={goBackToCategories}>
                <i className="fad fa-chevron-left left-button-icon"/>
                Back
            </Button>
            <h1>Select a service</h1>
        </header>

        {services?.length && <div>
            {services.map((service, index: number) => {
                return <BookingServiceTile onSubmit={props.onSubmit} bookingService={service} key={index}
                                           shouldShowDescriptionOutlet={true}
                                           shouldShowOutletImage={services.some((service) => service.logo_url)}/>
            })}
        </div>}

        <p className='link-text' onClick={setContactMode}>Can't find the right service? Send us a
            message</p>
    </section>
};
