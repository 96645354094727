import {SETTINGS} from "../common/settings";
import {Company} from "../models/company";
import {Customer} from "../models/customer";
import {DaySlot} from "../models/day-slot";
import moment from "moment-timezone";
import {BookingService} from "../models/service";
import {CustomField} from "../models/custom-field";

export const getCustomFields = async (id: number) => {
    return fetch(`${SETTINGS.getApiUrl()}/portal/company/${id}/custom-field`, {method: 'GET'})
        .then((response) => {
            return response.json();
        })
        .then((response: { response: { custom_fields: CustomField[] }, error: boolean, errors?: string[] }) => {
            if (response.error) {
                throw new Error((response.errors || []).join('\n'));
            }
            return response?.response?.custom_fields as CustomField[];
        });
}
