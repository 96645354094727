import {useEffect, useState} from "react";
import {detectMobileDevice, UTIL_FUNCTIONS} from "../utils/util-functions";

export function useIsMobileDevice(byWidth: boolean = true) {
    const [isMobileDevice, setIsMobileDevice] = useState<boolean>(byWidth ? UTIL_FUNCTIONS.isMobileDevice() : detectMobileDevice());

    useEffect(() => {
        if (byWidth) {
            window.addEventListener('resize', updateIsMobileDevice);

            return () => {
                window.removeEventListener('resize', updateIsMobileDevice);
            }
        }
    });

    const updateIsMobileDevice = () => {
        setIsMobileDevice(UTIL_FUNCTIONS.isMobileDevice());
    }

    return isMobileDevice;
}