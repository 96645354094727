import React, {FunctionComponent, useCallback, useContext, useEffect} from "react";
import {Button} from "../ui-components/Button/Button";
import './BookingServiceCategory.scss';
import {BookingServiceCategory} from "../../models/service-category";
import {ServiceContext} from "../../contexts/ServiceContext";
import {useNavigate} from "react-router-dom";
import {SlugContext} from "../../contexts/SlugContext";

interface BookingServiceCategoryTileProps {
    serviceCategory: BookingServiceCategory;
}

export const BookingServiceCategoryTile: FunctionComponent<BookingServiceCategoryTileProps> = (props) => {
    const serviceContext = useContext(ServiceContext);
    const slugContext = useContext(SlugContext);
    const navigate = useNavigate();

    const openBookingServiceCategory = useCallback(() => {
        serviceContext.setSelectedCategory(props.serviceCategory);
        navigate(`/${slugContext.slugName}/service`);
    }, [props.serviceCategory, serviceContext]);

    return (
        <section className={`booking-service-category`}>
            <section className="booking-service-category-info">
                <p className='booking-service-category-title'>{props.serviceCategory.title}</p>
            </section>

            <section className="booking-service-category-actions">
                <Button className='large-button' onClick={openBookingServiceCategory}>
                    Open
                    <i className="fad fa-chevron-circle-right fa-md right-button-icon"/>
                </Button>
            </section>
        </section>)
};
