import React, {FunctionComponent, useCallback, useContext} from 'react';
import "./WeekDaySlot.scss"
import {DaySlot} from "../../../../models/day-slot";
import {CompanyContext} from "../../../../contexts/CompanyContext";


interface WeekDaySlotProps {
    slot: DaySlot;
    isActive: boolean;
    onSetSlot: (slot: DaySlot) => void;
}

export const WeekDaySlot: FunctionComponent<WeekDaySlotProps> = (props) => {
    const company = useContext(CompanyContext).company;
    const getClassNames = useCallback(() => {
        const classes = ['week-day-slot'];
        if (props.isActive) {
            classes.push('active-week-day-slot');
        }
        return classes.join(' ');
    }, [props.slot, props.isActive]);

    const getTime = useCallback(() => {
        if (company?.is_booking_portal_manual_timezone) {
            let timezone = props.slot.startTime.format('z');
            if(Number.isInteger(parseInt(timezone))) {
                timezone = `GMT${timezone}`;
            }
            return props.slot.startTime.format('h:mm A ') + timezone;
        } else {
            return props.slot.startTime.format('h:mm A');
        }
    }, [company, props.slot]);

    return (
        <div className={getClassNames()} onClick={() => {
            props.onSetSlot(props.slot);
        }}>{getTime()}</div>
    );
};
