import React, {useContext, useEffect, useState} from "react";
import {CompanyContext} from "./CompanyContext";
import {SubmitContext} from "./SubmitContext";
import moment from "moment-timezone";
import {ToastService} from "../service/ToastService";

const GOOGLE_CLIENT_ID = '251448530169-so0leujkpqqvb59aumu4lju7hibena5d.apps.googleusercontent.com';
const GOOGLE_API_KEY = 'AIzaSyDOY5A7b30gqlJHN8XpavqwFSn0PGmt5oY';

const GOOGLE_DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];

const GOOGLE_SCOPES = 'https://www.googleapis.com/auth/calendar';

interface GoogleContextState {
    addEventToGoogleCalendar: () => void;
}

export const GoogleContext = React.createContext({} as GoogleContextState);

export const GoogleProvider: React.FunctionComponent = ({children}) => {
    const [isGoogleInited, setGoogleInited] = useState(false);
    const company = useContext(CompanyContext).company;
    const submitContext = useContext(SubmitContext);

    useEffect(() => {
        if (!isGoogleInited) {
            return;
        }
        const interval = setInterval(() => {
            // @ts-ignore
            if (gapi) {
                // @ts-ignore
                gapi.load('client:auth2', () => {
                    initGoogleClient();
                });
                clearInterval(interval);
            }
        }, 500);
    }, [isGoogleInited]);

    const initGoogleClient = () => {
        // @ts-ignore
        gapi.client.init({
            apiKey: GOOGLE_API_KEY,
            clientId: GOOGLE_CLIENT_ID,
            discoveryDocs: GOOGLE_DISCOVERY_DOCS,
            scope: GOOGLE_SCOPES
        })
            .then(() => {
                // @ts-ignore
                gapi.auth2.getAuthInstance().isSignedIn.listen((isSignedIn: boolean) => {
                    insertGoogleEvent(isSignedIn);
                });

                // @ts-ignore
                gapi.auth2.getAuthInstance().signOut();
                // @ts-ignore
                gapi.auth2.getAuthInstance().signIn();
            })
    }

    const insertGoogleEvent = (isSignedIn: boolean) => {
        if (isSignedIn) {
            // @ts-ignore
            const request = gapi.client.calendar.events.insert({
                'calendarId': 'primary',
                'resource': {
                    'summary': company?.title,
                    'start': {
                        'dateTime': submitContext.activeSlot?.startTime.format('YYYY-MM-DDTHH:mm:ss'),
                        'timeZone': moment.tz.guess()
                    },
                    'end': {
                        'dateTime': submitContext.activeSlot?.endTime.format('YYYY-MM-DDTHH:mm:ss'),
                        'timeZone': moment.tz.guess()
                    }
                }
            });
            request.execute(() => {
                ToastService.middleScreenToast('We have added event to your calendar.')
            });
        }
    }

    const addEventToGoogleCalendar = () => {
        setGoogleInited(true);
    }

    return (
        <GoogleContext.Provider
            value={{addEventToGoogleCalendar}}>
            {children}
        </GoogleContext.Provider>
    )
}
