import React, {useCallback, useContext, useEffect, useState} from "react";
import {BookingServiceCategory} from "../models/service-category";
import {CompanyContext} from "./CompanyContext";
import {CONSTANTS} from "../common/constants";
import {getBookingServiceInformation} from "../api/service.api";

interface ServiceContextState {
    setSelectedCategory: (bookingCategory: BookingServiceCategory) => void;
    categories: BookingServiceCategory[];
    selectedCategory: BookingServiceCategory | null;
}

export const ServiceContext = React.createContext({} as ServiceContextState);

export const ServiceProvider: React.FunctionComponent = ({children}) => {
    const [selectedCategory, setSelectedCategory] = useState<BookingServiceCategory | null>(null);
    const [categories, setCategories] = useState<BookingServiceCategory[]>([]);
    const company = useContext(CompanyContext).company;

    const loadCategories = useCallback(async () => {
        if (company) {
            const response = await getBookingServiceInformation(company.id)
                .catch(() => {

                });
            if (response) {
                setCategories(response.filter((category: BookingServiceCategory) => {
                    return category.booking_portal_services?.length > 0;
                }).map((category: BookingServiceCategory)=>{
                    category.booking_portal_services = category.booking_portal_services.sort((bookingServiceA, bookingServiceB)=>{
                        return bookingServiceA.order - bookingServiceB.order;
                    })
                    return category;
                }));
            }
        }
    }, [company]);

    useEffect(() => {
        if (company && company.id && (company.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SERVICE || company.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE_AND_SERVICE)) {
            loadCategories();
        }
    }, [company])

    return (
        <ServiceContext.Provider
            value={{setSelectedCategory, categories, selectedCategory}}>{children}
        </ServiceContext.Provider>)
}
