import {Toast} from "../components/Toast/Toast";
import React, {FunctionComponent, FunctionComponentElement} from "react";
import ReactDOM from "react-dom";

export class ToastService {
    private static toastCounter = 0;

    public static middleScreenToast(text: string, duration: number = 5000) {
        const toast = React.createElement(Toast, {id: ToastService.toastCounter, text, duration});
        this.createToast(toast, duration);
    }

    public static middleScreenErrorToast(text: string, duration: number = 5000) {
        const toast = React.createElement(Toast, {id: ToastService.toastCounter, text, isErrorToast: true, duration});
        this.createToast(toast, duration);
    }

    public static middleScreenWarningToast(text: string, duration: number = 5000) {
        const toast = React.createElement(Toast, {id: ToastService.toastCounter, text, isWarningToast: true, duration,});
        this.createToast(toast, duration);
    }

    public static closeToast(id: number) {
        const toastContainer = document.getElementById('toast-container-' + id);
        if (toastContainer && toastContainer.parentElement) {
            toastContainer.parentElement.removeChild(toastContainer);
        }
    }

    private static createToast(toast: FunctionComponentElement<any>, duration: number) {
        const toastContainer = document.createElement('div');
        toastContainer.id = 'toast-container-' + ToastService.toastCounter;
        ToastService.toastCounter++;
        toastContainer.className = 'toast-container';
        document.body.appendChild(toastContainer);
        ReactDOM.render(toast, toastContainer);

        setTimeout(() => {
            if (toastContainer && toastContainer.parentElement) {
                toastContainer.parentElement.removeChild(toastContainer);
            }
        }, duration);
    }
}
