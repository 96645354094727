import React, {FunctionComponent, useCallback, useContext} from 'react';
import './ServiceCategoryStep.scss';
import {Preloader} from "../../components/Preloader/Preloader";
import {ServiceContext} from "../../contexts/ServiceContext";
import {BookingServiceCategoryTile} from "../../components/BookingServiceCategory/BookingServiceCategory";


interface ServiceCategoryStepProps {
    onSetContactMode: () => void;
}

export const ServiceCategoryStep: FunctionComponent<ServiceCategoryStepProps> = (props) => {
    const categories = useContext(ServiceContext).categories;

    const setContactMode = useCallback(() => {
        props.onSetContactMode();
    }, [props.onSetContactMode]);

    return <section className='step-container service-category-step'>
        <h1>Select a service category</h1>
        {categories?.length ? <div>
            {categories.map((category, index: number) => {
                return <BookingServiceCategoryTile serviceCategory={category} key={index}/>
            })}
        </div> : <div className='preloader-container'>
            <Preloader/>
        </div>}

        <p className='link-text' onClick={setContactMode}>Can't find the right service category? Send us a
            message</p>
    </section>
};
