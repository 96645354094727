import React, {FunctionComponent, useCallback} from 'react';
import {DayWithSlots} from "../WeekCalendar";
import moment from "moment";
import "./WeekDay.scss"


interface WeekDayProps {
    dayWithSlot: DayWithSlots;
    isActive: boolean;
    onSetActiveDay: (dayWithSlot: DayWithSlots) => void;
}

export const WeekDay: FunctionComponent<WeekDayProps> = (props) => {
    const getClassNames = useCallback(() => {
        const classes = ['week-day'];

        if (props.dayWithSlot.slots.length) {
            classes.push('week-day-with-time');
        }
        if (isEndOfWeekDay(props.dayWithSlot)) {
            classes.push('end-of-week-day');
        }
        if (props.isActive) {
            classes.push('active-week-day');
        }

        return classes.join(' ');
    }, [props.dayWithSlot, props.isActive]);

    const isEndOfWeekDay = (dayWithSlot: DayWithSlots) => {
        return dayWithSlot.day.day() === moment().day('Sunday').day();
    };

    return (
        <>
            <div className={getClassNames()} onClick={() => {
                props.onSetActiveDay(props.dayWithSlot);
            }}>
                <span className='day-name'>{props.dayWithSlot.day.format('ddd')}</span>
                <span className='day-date'>{props.dayWithSlot.day.format('MMM DD')}</span>
            </div>
            {
                isEndOfWeekDay(props.dayWithSlot) ? <div className="end-of-week-divider"/> : null
            }
        </>
    );
};
