import React, {FunctionComponent, useEffect, useState} from 'react';

import './Toast.scss';
import {ToastService} from "../../service/ToastService";

interface ToastProps {
    id: number;
    text: string;
    isErrorToast?: boolean;
    isWarningToast?: boolean;
    duration: number;
}

export const Toast: FunctionComponent<ToastProps> = (props) => {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [isHiding, setIsHiding] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setIsShowing(true);
        }, 50);
    }, [false])

    setTimeout(() => {
        setIsShowing(false);
        setIsHiding(true);
    }, props.duration - 500);

    const getClassName = () => {
        const baseClassName = 'toast';
        const classNames = [baseClassName];
        if (isShowing) {
            classNames.push('show-toast');
        }
        if (isHiding) {
            classNames.push('hide-toast');
        }

        if (props.isErrorToast) {
            classNames.push('error-toast')
        }
        if (props.isWarningToast) {
            classNames.push('warning-toast');
        }
        return classNames.join(' ');
    }

    const closeToast = () => {
        ToastService.closeToast(props.id);
    }

    return (
        <div className={getClassName()}>
            <i onClick={closeToast} className={'toast-icon toast-close-icon'}>×</i>
            {props.text}
        </div>
    );
};
